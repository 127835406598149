'use client';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

export const MessageListener = () => {
	const router = useRouter();

	// useEffect(() => {
	// 	const handleMessage = (event: any) => {
	// 		if (event.data === 'reload') {
	// 			window.location.reload();
	// 		}
	// 	};

	// 	window.addEventListener('message', handleMessage);

	// 	// Очистка обработчика при размонтировании компонента
	// 	return () => {
	// 		window.removeEventListener('message', handleMessage);
	// 	};
	// }, [router]);

	useEffect(() => {
		const handleMessage = (event: any) => {
			if (JSON.parse(event.data)[0] === 'updateArticles') {
				const url = new URL(window.location.href);
				url.searchParams.set('reloaded', 'true');
				window.location.href = url.toString();
			}
		};

		document.addEventListener('message', handleMessage);

		return () => {
			document.removeEventListener('message', handleMessage);
		};
	}, []);

	useEffect(() => {
		const url = new URL(window.location.href);
		if (url.searchParams.get('reloaded') === 'true') {
			window.postMessage(
				(JSON.stringify(['setTeasersLoadingState', false]), '*')
			);
			url.searchParams.delete('reloaded');
			router.replace(url.pathname + url.search);
		}
	}, [router]);

	return null;
};
