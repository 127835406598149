import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetArticlesFilter } from '../Api/Fetchs/getPostArticle';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
	filter: Array<DataArticle>;
}
export function* getPostArticleNewSaga(
	action: ReturnType<typeof getPostsNews>
): any {
	try {
		yield put(getPostsLoading());
		const params = action.payload || {};
		const response = yield call(GetArticlesFilter, params);
		const payload = yield call([response, 'json']);
		yield put(getPostsSuccess(payload));
	} catch (error) {
		yield put(getPostsError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false,
	filter: []
};

const getPostArticleNew = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getPostsLoading: state => {
			state.Loading = true;
		},
		getPostsSuccess: (state, action) => {
			// state.data.push(...action.payload);
			state.data = action.payload;
			state.Loading = false;
		},
		getPostsError: (state, action) => {
			state.Error = action.payload;
		},

		setFilter: (state, action) => {
			// state.filter.push(...action.payload.map((el: any) => el.id));
			state.filter = action.payload.map((el: any) => el.id);
		}
	}
});

export const GET_POSTSNEWS = 'article/getPostsNews';

export const getPostsNews = createAction<{
	topic?: any;
	whiteList: any;
	filter_ids?: any;
	pages: any;
}>(GET_POSTSNEWS);

export const { getPostsSuccess, getPostsLoading, getPostsError, setFilter } =
	getPostArticleNew.actions;
export default getPostArticleNew.reducer;
