export const GetTopic = ({ params, topic, whiteList }: any) => {
	return fetch('/api?action=articles', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			// action: 'articles',
			block_id: 84683,
			count: params,
			fields: 4194303,
			image_width: 360,
			image_height: 200,
			topics: [topic],
			filter_publishers: whiteList,
			ctx: 'STORIES'
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
