
export const GetComments = (params: any) => {
	return fetch('/api?action=article_details', {
		mode: 'cors',
		method: 'POST',

		body: JSON.stringify({
			block_id: 84683,
			article_id: params,
			fields: 4194303,
			image_height: 150,
			image_width: 250
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
