export const getPostSearchFetch = ({
	pages,
	offset,
	value,
	order,
	filters
}: {
	pages: number;
	offset: number;
	value: string;
	order: string;
	filters: string;
}) => {
	return fetch(`/apiSearch/search`, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			query: value,
			limit: pages,
			offset: offset,
			order: order,
			locale: 'ru_RU',
			agencyId: 0,
			filters: {
				period: filters
			}
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
