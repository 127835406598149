'use client';
import { Provider } from 'react-redux';
import { persister, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { useEffect, useState } from 'react';

export const ProviderClient = ({ children }: any): JSX.Element => {
	const [isClient, setIsClient] = useState(false);

	useEffect(() => {
		setIsClient(true);
	}, []);

	// Проверка на null или undefined store
	if (!store) {
		console.error('Redux store is null or undefined');
	}

	if (!isClient) {
		return <>{children}</>;
	}

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persister}>
				{children}
			</PersistGate>
		</Provider>
	);
};
