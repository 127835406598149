import { createAction, createSlice } from '@reduxjs/toolkit';
import { put, call } from 'redux-saga/effects';
import { GetTopic } from '../Api/Fetchs/getPostTopic';
import { DataArticle } from './Saga';

interface IPostState {
	data: Array<DataArticle>;
	Loading: boolean;
	Error: boolean;
}

export function* getTopicSaga(action: ReturnType<typeof getTopic>): any {
	try {
		yield put(getTopicLoading());
		const params = action.payload || {};
		const response = yield call(GetTopic, params);
		const payload = yield call([response, 'json']);
		yield put(getTopicSuccess(payload));
	} catch (error) {
		yield put(getTopicError(error));
	}
}

const initialState: IPostState = {
	data: [],
	Loading: true,
	Error: false
};

const getPostTopic = createSlice({
	name: 'posts',
	initialState,
	reducers: {
		getTopicLoading: state => {
			state.Loading = true;
		},
		getTopicSuccess: (state, action) => {
			state.data = action.payload;
			state.Loading = false;
		},
		getTopicError: (state, action) => {
			state.Error = action.payload;
		},
		restData: state => {
			state.data = [];
		}
	}
});

export const GET_TOPIC = 'topic/getTopic';

export const getTopic = createAction<{
	params?: any;
	topic?: any;
	whiteList: any;
}>(GET_TOPIC);

export const { getTopicSuccess, getTopicError, getTopicLoading, restData } =
	getPostTopic.actions;
export default getPostTopic.reducer;
