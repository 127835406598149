export const GetProperty = () => {
	return fetch('/api?action=properties', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683,
			tags_type: 'MAIN'
		}),
		headers: new Headers({ 'content-type': 'application/json' })
		// next: { revalidate: 18000 }
	});
};
