'use client';

import { getProperty } from '@/Redux/saga/getPostProperty';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const GetPropertyss = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getProperty());
	}, []);

	return <></>;
};
