export const GetPostSimular = ({ storyId }: any) => {
	return fetch('/api?action=articles', {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify({
			block_id: 84683,
			fields: 4194303,
			image_width: 250,
			image_height: 150,
			count: 3,
			ctx: 'STORIES',
			sort_type: 'DATE',
			filter_ids: [Number(storyId)],
			similar_article_id: Number(storyId)
		}),
		headers: new Headers({ 'content-type': 'application/json' })
	});
};
